import React from 'react';

import { Header } from 'reaxl-header/demo';

export default function DemoHeader() {

    return (
        <Header
            atcPartnerLogoSrc="/content/static/img/partner/ford/ford-header-atc-logo.svg"
            partnerLogoSrc="/content/static/img/partner/partner-header-logo.png"
        />
    );
}
